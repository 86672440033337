import { Component, OnInit } from '@angular/core';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import PATH from '@assets/routes/routes.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppMainComponent } from './app.main.component';
import { Policy } from '@app/models/policy';

@Component({
	selector: 'app-menu',
	templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
	model: any[];
	isVisy: boolean = false;

	constructor(
		private accountService: AccountService,
		public app: AppMainComponent,
		private jwtHelper: JwtHelperService,
	)
	{
		this.isVisy = this.accountService.isAuthorized(Policy.ViSyUser);
	}

	ngOnInit() {
		this.model = [
			{ label: 'MENU.HOME', icon: 'fa fa-fw fa-home', routerLink: ['/' + PATH.HOME] },
			{
				label: 'MENU.ONLINE', icon: 'fa fa-fw fa-wifi', routerLink: ['/' + PATH.ONLINE], items: [
					{ label: 'MENU.FAHRZEUGE', module: Module.OnlineVehicles, icon: 'fa fa-fw fa-map', routerLink: ['/' + PATH.ONLINE_FAHRZEUGE] },
					{ label: 'MENU.LEERUNGEN', module: Module.OnlineCollections, icon: 'fa fa-fw fa-trash', routerLink: ['/' + PATH.ONLINE_LEERUNGEN] },
				]
			},
			{
				label: 'MENU.BHV', module: Module.BHV, icon: 'fa fa-fw fa-dumpster', routerLink: ['/' + PATH.BHV], items: [
					{
						label: 'MENU.GEFAESS', icon: 'fa fa-fw fa-dumpster', routerLink: ['/' + PATH.GEFAESS], items: [
							{ label: 'MENU.GEFAESS', icon: 'fa fa-fw fa-dumpster', routerLink: ['/' + PATH.GEFAESS] },
							{ label: 'MENU.TYP', icon: 'fa fa-fw fa-sitemap', routerLink: ['/' + PATH.GEFAESS_TYP] },
							{ label: 'MENU.STATUS', icon: 'fa fa-fw fa-question', routerLink: ['/' + PATH.GEFAESS_STATUS] },
							{ label: 'MENU.ZUSTAND', icon: 'fa fa-fw fa-traffic-light', routerLink: ['/' + PATH.GEFAESS_ZUSTAND] },
							{ label: 'MENU.ABFUHRRHYTHMUS', icon: 'fa fa-fw fa-repeat', routerLink: ['/' + PATH.ABFUHRRHYTHMUS] },
							{ label: 'MENU.SPERRE', icon: 'fa fa-fw fa-repeat', routerLink: ['/' + PATH.GEFAESS_SPERRE] },
						]
					},
					{
						label: 'MENU.OBJEKT', icon: 'fa fa-fw fa-house', routerLink: ['/' + PATH.OBJEKT], items: [
							{ label: 'MENU.OBJEKT', icon: 'fa fa-fw fa-house', routerLink: ['/' + PATH.OBJEKT] },
							{ label: 'MENU.TYP', icon: 'fa fa-fw fa-sitemap', routerLink: ['/' + PATH.OBJEKT_TYP] },
							{ label: 'MENU.GRUPPE', icon: 'fa fa-fw fa-layer-group', routerLink: ['/' + PATH.OBJEKT_GRUPPE] },
							{ label: 'MENU.STATUS', icon: 'fa fa-fw fa-question', routerLink: ['/' + PATH.OBJEKT_STATUS] },
						]
					},
					{ label: 'MENU.FIRMA', icon: 'fa fa-fw fa-building', routerLink: ['/' + PATH.FIRMA] },
					{ label: 'MENU.KUNDE', icon: 'fa fa-fw fa-users', routerLink: ['/' + PATH.KUNDE] },
					{ label: 'MENU.AUFTRAG', icon: 'fa fa-fw fa-users', routerLink: ['/' + PATH.AUFTRAG] },
				]
			},
			{
				label: 'MENU.RUECKFAHRKATASTER', module: Module.ReversingCadastral, icon: 'fa fa-fw fa-backward', routerLink: ['/' + PATH.RK], items: [
					{ label: 'MENU.RUECKFAHRKATASTER', icon: 'fa fa-fw fa-clipboard', routerLink: ['/' + PATH.RK_STRECKE] },
					{ label: 'MENU.AUFZEICHNUNG', icon: 'fa fa-fw fa-video', routerLink: ['/' + PATH.RK_AUFZEICHNUNG] },
					{
						label: 'MENU.STAMMDATEN', icon: 'fa fa-fw fa-archive', routerLink: ['/' + PATH.RK_STAMMDATEN], items: [
							{ label: 'MENU.GEFAHR', icon: 'fa fa-fw fa-car-crash', routerLink: ['/' + PATH.RK_GEFAHR] },
							{ label: 'MENU.GRUND', icon: 'fa fa-fw fa-clipboard-list', routerLink: ['/' + PATH.RK_GRUND] },
							{ label: 'MENU.MASSNAHME', icon: 'fa fa-fw fa-clipboard-check', routerLink: ['/' + PATH.RK_MASSNAHME] },
							{ label: 'MENU.STATUS', icon: 'fa fa-fw fa-question', routerLink: ['/' + PATH.RK_STATUS] },
						]
					},
				]
			},
			{
				label: 'MENU.BERICHTE', module: Module.Masterdata, icon: 'fa fa-fw fa-file-alt', routerLink: ['/' + PATH.BERICHTE], items: [
					{ label: 'MENU.LEERUNGEN', icon: 'fa fa-fw fa-trash', routerLink: ['/' + PATH.LEERUNG] },
					{ label: 'MENU.FAHRTBERICHTE', icon: 'fa fa-fw fa-truck-arrow-right', routerLink: ['/' + PATH.FAHRTEBRICHT] },
				]
			},
			{
				label: 'MENU.STAMMDATEN', module: Module.Masterdata, icon: 'fa fa-fw fa-archive', routerLink: ['/' + PATH.STAMMDATEN], items: [
					{
						label: 'MENU.FAHRZEUG', icon: 'fa fa-fw fa-truck', routerLink: ['/' + PATH.FAHRZEUG], items:
						this.isVisy ? 
						[
							{ label: 'MENU.FAHRZEUG', icon: 'fa fa-fw fa-truck', routerLink: ['/' + PATH.FAHRZEUG] },
							{ label: 'MENU.FAHRGESTELL', icon: 'fa fa-fw fa-truck-pickup', routerLink: ['/' + PATH.FAHRGESTELL] },
							{ label: 'MENU.AUFBAU', icon: 'fa fa-fw fa-trailer', routerLink: ['/' + PATH.AUFBAU] },
							{ label: 'MENU.LIFTER', icon: 'fa fa-fw fa-truck-loading', routerLink: ['/' + PATH.LIFTER] },
							{ label: 'MENU.KOMPONENTE', icon: 'fa fa-fw fa-tablet-screen-button', routerLink: ['/' + PATH.KOMPONENTE] },
							{ label: 'MENU.KOMPONENTE_TYP', icon: 'fa fa-fw fa-computer-mouse', routerLink: ['/' + PATH.KOMPONENTE_TYP] },
							{ label: 'MENU.KOMPONENTE_BEZEICHNER', icon: 'fa fa-fw fa-tag', routerLink: ['/' + PATH.KOMPONENTE_BEZEICHNER] },
							//{ label: 'MENU.FAHRZEUG_ABWICKLUNG_ABSCHNITT', icon: 'fa fa-fw fa-clipboard', routerLink: ['/' + PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT] },
							//{ label: 'MENU.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP', icon: 'fa fa-fw fa-clipboard', routerLink: ['/' + PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP] },
						]
						:
						[
							{ label: 'MENU.FAHRZEUG', icon: 'fa fa-fw fa-truck', routerLink: ['/' + PATH.FAHRZEUG] },
							{ label: 'MENU.FAHRGESTELL', icon: 'fa fa-fw fa-truck-pickup', routerLink: ['/' + PATH.FAHRGESTELL] },
							{ label: 'MENU.AUFBAU', icon: 'fa fa-fw fa-trailer', routerLink: ['/' + PATH.AUFBAU] },
							{ label: 'MENU.LIFTER', icon: 'fa fa-fw fa-truck-loading', routerLink: ['/' + PATH.LIFTER] },
						]
					},
					{
						label: 'MENU.GEOGRAFIE', icon: 'fa fa-fw fa-earth-europe', routerLink: ['/' + PATH.GEOGRAPHIE], items: [
							{ label: 'MENU.STRASSE', icon: 'fa fa-fw fa-road', routerLink: ['/' + PATH.STRASSE] },
							{ label: 'MENU.ORT', icon: 'fa fa-fw fa-tree-city', routerLink: ['/' + PATH.ORT] },
							{ label: 'MENU.GEMEINDE', icon: 'fa fa-fw fa-city', routerLink: ['/' + PATH.GEMEINDE] },
							{ label: 'MENU.LAND', icon: 'fa fa-fw fa-flag-usa', routerLink: ['/' + PATH.LAND] },
							{ label: 'MENU.GEBIET', icon: 'fa fa-fw fa-map-pin', routerLink: ['/' + PATH.GEBIET] },
						]
					},
					{
						label: 'MENU.TOUR', icon: 'fa fa-fw fa-route', routerLink: ['/' + PATH.TOUR], items: [
							{ label: 'MENU.TOUR', icon: 'fa fa-fw fa-route', routerLink: ['/' + PATH.TOUR] },
							{ label: 'MENU.TYP', icon: 'fa fa-fw fa-sitemap', routerLink: ['/' + PATH.TOUR_TYP] },
							{ label: 'MENU.GRUPPE', icon: 'fa fa-fw fa-layer-group', routerLink: ['/' + PATH.TOUR_GRUPPE] },
						]
					},
					{
						label: 'MENU.ABFALLART', icon: 'fa fa-fw fa-boxes', routerLink: ['/' + PATH.ABFALLART], items: [
							{ label: 'MENU.ABFALLART', icon: 'fa fa-fw fa-boxes', routerLink: ['/' + PATH.ABFALLART] },
							{ label: 'MENU.TYP', icon: 'fa fa-fw fa-sitemap', routerLink: ['/' + PATH.ABFALLART_TYP] },
							{ label: 'MENU.AVV', icon: 'fa fa-fw fa-trash', routerLink: ['/' + PATH.AVV] },
						]
					},
					{ label: 'MENU.ANREDE', icon: 'fa fa-fw fa-venus-mars', routerLink: ['/' + PATH.ANREDE] },
					{ label: 'MENU.MITARBEITER', icon: 'fa fa-fw fa-people-carry', routerLink: ['/' + PATH.MITARBEITER] },
					{ label: 'MENU.STATUSBARCODES', icon: 'fa fa-fw fa-barcode', routerLink: ['/' + PATH.LEERUNG_BARCODE_STATUS] },
					{ label: 'MENU.VOLUMEN', icon: 'fa fa-fw fa-glass-whiskey', routerLink: ['/' + PATH.VOLUMEN] },
				]
			},
			{
				label: 'MENU.EINSTELLUNGEN', icon: 'fa fa-fw fa-wrench', routerLink: ['/' + PATH.SETTINGS], items: [
					{ label: 'MENU.ALLGEMEIN', icon: 'fa fa-fw fa-cog', routerLink: ['/' + PATH.GENERAL_SETTINGS] },
					{ label: 'MENU.PASSWORT', icon: 'fa fa-fw fa-lock', routerLink: ['/' + PATH.CHANGE_PASSWORD] },
				]
			},
			{
				label: 'MENU.ADMIN', icon: 'fa fa-fw fa-chess-king', routerLink: ['/' + PATH.ADMIN], items: [
					{ label: 'MENU.NUTZER', icon: 'fa fa-fw fa-user-cog', routerLink: ['/' + PATH.USERS] },
					{ label: 'MENU.ORGANISATION', icon: 'fa fa-fw fa-fingerprint', routerLink: ['/' + PATH.ORGANIZATION] },
					{
						label: 'MENU.VISY', icon: 'fa fa-fw fa-eye', routerLink: ['/' + PATH.VISY], items: [
							{ label: 'MENU.NUTZER', icon: 'fa fa-fw fa-user-cog', routerLink: ['/' + PATH.VISY_USERS] },
							{ label: 'MENU.CONNECTION_SETTINGS', icon: 'fa fa-fw fa-database', routerLink: ['/' + PATH.CONNECTION_SETTINGS] },
						]
					}
				]
			},
		];
	}

	authorized(item) {
		if (item.module != null) {
			if (!this.accountService.checkPermissions(item.module, Operation.READ)) {
				return false;
			}
		}
		const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (role === 'ViSyAdministrator') {
			return true;
		}
		if (item.routerLink[0].includes('admin')) {
			if (item.routerLink[0].includes('admin/visy')) {
				return false;
			}

			if (role === 'CustomerAdministrator') {
				return true;
			}
			return false;
		}
		return true;
	}

	isEmptyParent(item) {
		let result = true;
		if (item.items != null) {
			item.items.forEach(i => {
				if (i.module != null) {
					if (this.accountService.checkPermissions(i.module, Operation.READ)) {
						result = false;
					}
				} else {
					result = false;
				}
			});
		} else {
			return false;
		}
		return result;
	}

	onMenuClick() {
		this.app.menuClick = true;
	}
}
