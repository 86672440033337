import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AppMainComponent } from './app.main.component';
import { Globals } from '@app/common/global_variables';
import { AccountService } from '@app/services/account.service';

@Component({
	selector: 'twofactorcheck',
	templateUrl: './twofactor.component.html'
})
export class TwoFactorCheckComponent {
	static twoFactorCheckPopUpShown: boolean;
	constructor(
		private accountService: AccountService,
		private messageService: MessageService,
		public translate: TranslateService,
		public app: AppMainComponent,
		private globals: Globals,
	) {
	}
	twoFactorAuthCode: string;
	twoFactorCheckPopUpShown: boolean = false;
	private twoFactorResult: boolean = false;
	private resolveFn?: (result: boolean) => void;

	validateTwoFactorCode(): void {
		this.accountService.validateTwoFactorCode(this.twoFactorAuthCode).then(res => {
			if (res) {
				// Only close the pop-up if the code successfully went through
				this.twoFactorCheckPopUpShown = false;
				this.twoFactorResult = true;
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
			this.twoFactorResult = false;
		});
	}

	show(): Promise<boolean> {
		this.twoFactorCheckPopUpShown = true;
		this.twoFactorAuthCode = null;
		return new Promise<boolean>((resolve) => {
			this.resolveFn = resolve;
		});
	}

	onDialogHide(): void {
		if (this.resolveFn) {
			this.resolveFn(this.twoFactorResult); // Resolve the promise when closed
		}
	}
}
