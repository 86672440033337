<!-- 2FA QR Code Pop-up -->
<p-dialog header="{{'TWOFACTOR.SCAN_TITLE'|translate}}" [modal]="true" [(visible)]="twoFactorCodePopUpShown" [style]="{ width: '25rem' }">
    <p style="width: 100%; text-align: center;"><span class="p-text-secondary block mb-8">{{'TWOFACTOR.SCAN_INFO'|translate}}</span></p>
	<div class="card">
		<img width="100%" style="border: 1px solid gray; image-rendering: pixelated;" id="twoFactorQrCodeElement" [src]="twoFactorQrCode">
		<code>
			<span style="width:100%; word-wrap:break-word; display:inline-block;">
				{{ twoFactorManualEntry }}
			</span>
		</code>
	</div>
	<button style="width: 100%" type="button" pButton pRipple
		icon="fa fa-check"
		class="p-button-secondary"
		(click)="openCodeCheckerWindow()"></button>
</p-dialog>

<!-- 2FA Check Pop-up -->
<twofactorcheck #twoFactorPopup></twofactorcheck>

<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<div class="forms">
				<h6 class="p-mb-2">{{'SETTINGS.CHANGE_PASSWORD.OLD_PASSWORD'|translate}}</h6>
				<input type="password" pPassword [feedback]="false" (input)="oldPassword=$event.target.value">

				<h6 class="p-mt-5 p-mb-2">{{'SETTINGS.CHANGE_PASSWORD.NEW_PASSWORD'|translate}}</h6>
				<input type="password" pPassword [feedback]="false" (input)="newPassword1=$event.target.value">

				<h6 class="p-mt-5 p-mb-2">{{'SETTINGS.CHANGE_PASSWORD.CONFIRM_PASSWORD'|translate}}</h6>
				<input type="password" pPassword [feedback]="false" (input)="newPassword2=$event.target.value">

				<div class="buttons p-my-5">
					<button pButton type="button" [disabled]="loading" (click)="submitPasswd()"
						label="{{'SETTINGS.CHANGE_PASSWORD.CHANGE_PASSWORD'|translate}}">
					</button>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="forms">
				<h6 class="p-mb-2">{{'HEADERS.TwoFactorEnabled'|translate}}</h6>
			</div>
			<p-inputSwitch
				readonly="true"
				[ngModel]="twoFactorEnabled"
				(click)="changeTwoFa();">
			</p-inputSwitch>

			<!--<div class="buttons p-my-5">
				<button pButton type="button" [disabled]="loading || (twoFactorEnabled == oldTwoFactorEnabled)" (click)="submitTwoFactorSettings()"
					label="{{'SETTINGS.CHANGE_PASSWORD.CHANGE_TWODACTOR'|translate}}">
				</button>
			</div>-->
		</div>
	</div>
</div>