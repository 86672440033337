import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TwoFactorCheckComponent } from '@app/components/twofactor.component';
import PATH from '@assets/routes/routes.json';
import { AppMainComponent } from '@components/app.main.component';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@services/account.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './changepassword.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService],
})

export class ChangePasswordComponent implements OnInit {
	loading = false;
	newPassword1: string;
	newPassword2: string;
	oldPassword: string;
	returnUrl: string;
	@ViewChild('twoFactorPopup') twoFactorCheck!: TwoFactorCheckComponent;
	twoFactorCodePopUpShown: boolean = false;
	twoFactorQrCode: string;
    twoFactorManualEntry: string;
	twoFactorEnabled: boolean = false;
	oldTwoFactorEnabled: boolean = false;

	private readonly ContextCodeChecker_Enable: number = 1;
	private readonly ContextCodeChecker_Disable: number = 2;
	private readonly ContextCodeChecker_Undefined: number = 0;
	private currentCodeCheckerContext: number = this.ContextCodeChecker_Undefined;


	constructor(
		private accountService: AccountService,
		public app: AppMainComponent,
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		private route: ActivatedRoute,
		private router: Router,
		public translate: TranslateService,
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.EINSTELLUNGEN' },
			{ label: 'MENU.PASSWORT', routerLink: ['/' + PATH.CHANGE_PASSWORD] },
		]);
	}

	ngOnInit() {
		this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
		this.reloadCurrentTwoFactorSettings();
	}

	submitPasswd(): void {
		if (this.oldPassword && this.oldPassword !== '') {
			if (this.newPassword1 && this.newPassword1 !== '') {
				if (this.newPassword2 && this.newPassword2 !== '') {
					if (this.newPassword1 === this.newPassword2) {
						if (this.newPassword1 === this.oldPassword) {
							this.messageService.add({
								severity: 'warn',
								summary: this.translate.instant('MESSAGES.WARNING'),
								detail: this.translate.instant('MESSAGES.PASSWORDS_MATCHING'),
								life: 3000
							});
						}
						this.loading = true;
						this.accountService.changePassword(this.oldPassword, this.newPassword1).then(res => {
							this.messageService.add({
								severity: 'success',
								summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
								detail: this.translate.instant('MESSAGES.SAVED'),
								life: 3000
							});
							//setTimeout(() => {
							//	this.loading = false;
							//	this.router.navigate([this.returnUrl]);
							//}, 3000);
						}).catch(err => {
							this.loading = false;
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						}).finally(() => {
							this.loading = false;
						});
					} else {
						this.messageService.add({
							severity: 'error',
							summary: this.translate.instant('MESSAGES.ERROR'),
							detail: this.translate.instant('MESSAGES.PASSWORDS_NOT_MATCHING'),
							life: 3000
						});
					}
				} else {
					this.messageService.add({
						severity: 'error',
						summary: this.translate.instant('MESSAGES.ERROR'),
						detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('SETTINGS.CHANGE_PASSWORD.CONFIRM_PASSWORD') }),
						life: 3000
					});
				}
			} else {
				this.messageService.add({
					severity: 'error',
					summary: this.translate.instant('MESSAGES.ERROR'),
					detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('SETTINGS.CHANGE_PASSWORD.NEW_PASSWORD') }),
					life: 3000
				});
			}
		} else {
			this.messageService.add({
				severity: 'error',
				summary: this.translate.instant('MESSAGES.ERROR'),
				detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('SETTINGS.CHANGE_PASSWORD.OLD_PASSWORD') }),
				life: 3000
			});
		}
	}

	reloadCurrentTwoFactorSettings(): void {
		this.loading = true;
		this.accountService.check2faEnabled('current').then(result2faEnabled => {
			if(result2faEnabled && result2faEnabled['has2fa'] && result2faEnabled['has2fa'] === true) {
				this.twoFactorEnabled = true;
			} else {
				this.twoFactorEnabled = false;
			}
		}).catch(err => {
			this.loading = false;
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading = false;
			this.oldTwoFactorEnabled = this.twoFactorEnabled;
		})
	}

	changeTwoFa(): void {
		switch(this.twoFactorEnabled) {
			case true: this.disableTwoFactor(); break;
			case false: this.enableTwoFactor(); break;
			default: break;
		}
	}

	enableTwoFactor(): void {
		this.loading = true;
		this.accountService.getTwoFactorCode().then(res => {
			if (res) {
				this.twoFactorQrCode = res.qrCodeBase64;
				this.twoFactorManualEntry = res.manualEntry;
				this.currentCodeCheckerContext = this.ContextCodeChecker_Enable;
				this.twoFactorCodePopUpShown = true;
			}
		}).catch(e => {
			this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
		}).finally(() => {
			this.loading = false;
		});
	}

	disableTwoFactor(): void {
		this.currentCodeCheckerContext = this.ContextCodeChecker_Disable;
		this.openCodeCheckerWindow();
	}

	//getQrCode(): void {
	//	// Only do any of this if 2FA is disabled
	//	if (this.twoFactorEnabled) {
	//		this.twoFactorEnabled = false;
	//		this.messageService.add({
	//			severity: 'error',
	//			summary: this.translate.instant('MESSAGES.WARNING'),
	//			detail: this.translate.instant('MESSAGES.TWOFACTOR_DISABLED'),
	//			life: 3000
	//		});
	//	} else {
	//		this.loading = true;
	//		this.twoFactorCodePopUpShown = true
	//		this.accountService.getTwoFactorCode().then(res => {
	//			if (res) {
	//				this.twoFactorQrCode = res.qrCodeBase64;
	//				this.twoFactorManualEntry = res.manualEntry;
	//			}
	//		}).catch(e => {
	//			this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
	//		}).finally(() => {
	//			this.loading = false;
	//		});
	//	}
    //}

	async openCodeCheckerWindow() {
		this.twoFactorCodePopUpShown = false;
		var isTwoFactorCodeValid: boolean = await this.twoFactorCheck.show();
		if( isTwoFactorCodeValid ) {
			if(this.currentCodeCheckerContext == this.ContextCodeChecker_Enable) {
				this.loading = true;
				this.accountService.enableTwoFactorLogin().then(res => {
					this.messageService.add({
						severity: 'success',
						summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
						detail: this.translate.instant('MESSAGES.SAVED'),
						life: 3000
					});
					this.reloadCurrentTwoFactorSettings();
				}).catch(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				}).finally(() => {
					this.loading = false;
				});
			} else if(this.currentCodeCheckerContext == this.ContextCodeChecker_Disable) {
				this.loading = true;
				this.accountService.disableTwoFactorLogin().then(res => {
					this.messageService.add({
						severity: 'success',
						summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
						detail: this.translate.instant('MESSAGES.SAVED'),
						life: 3000
					});
					this.reloadCurrentTwoFactorSettings();
				}).catch(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				}).finally(() => {
					this.loading = false;
				});
			}
		} else {
			// TODO? Meldung kommt eh schon über das checker popup
		}
	}

	//submitTwoFactorSettings(): void {
	//	if(this.twoFactorEnabled != this.oldTwoFactorEnabled) {
	//		if(this.twoFactorEnabled) {
	//			this.loading = true;
	//			this.accountService.enableTwoFactorLogin().then(res => {
	//				this.messageService.add({
	//					severity: 'success',
	//					summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
	//					detail: this.translate.instant('MESSAGES.SAVED'),
	//					life: 3000
	//				});
	//				this.reloadCurrentTwoFactorSettings();
	//			}).catch(e => {
	//				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
	//			}).finally(() => {
	//				this.loading = false;
	//			});
	//		} else {
	//			this.loading = true;
	//			this.accountService.disableTwoFactorLogin().then(res => {
	//				this.messageService.add({
	//					severity: 'success',
	//					summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
	//					detail: this.translate.instant('MESSAGES.SAVED'),
	//					life: 3000
	//				});
	//				this.reloadCurrentTwoFactorSettings();
	//			}).catch(e => {
	//				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
	//			}).finally(() => {
	//				this.loading = false;
	//			});
	//		}
	//	}
	//}
}
