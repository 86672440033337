import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Module, Operation } from '@app/models/permission';
import { Policy } from '@app/models/policy';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import { Credentials, Message, User } from '@models/response';
import { LogService } from '@services/log.service';

@Injectable()
export class AccountService {

	constructor(
		private http: HttpClient,
		private jwtHelper: JwtHelperService,
		private logService: LogService
	) { }

	/**
	 * Baut die Header Options für den Request zusammen und gibt diese zurück.
	 */
	async getOptions(): Promise<{ withCredentials: boolean; headers: any; }> {
		let options = { withCredentials: true, headers: null };
		const jwt = localStorage.getItem('jwt');

		if (jwt) {
			if (this.jwtHelper.isTokenExpired(jwt)) {
				await this.refresh().then(res => {
					localStorage.setItem('jwt', res.accessToken);
					localStorage.setItem('refreshToken', res.refreshToken);
					options.headers = { authorization: 'Bearer ' + res.accessToken };
				});
			} else {
				options.headers = { authorization: 'Bearer ' + jwt };
			}
		}
		return options;
	}

	/**
	 * API Request zum Ändern des Passworts
	 *
	 * @param oldPassword Altes Passwort
	 * @param newPassword Neues Passwort
	 */
	changePassword(oldPassword: string, newPassword: string): Promise<any> {
		const body = { OldPassword: oldPassword, NewPassword: newPassword };
		return new Promise<any>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/changePassword`, body, options).toPromise());
		});
	}

	/**
	 * Funktion überprüft, ob der User die Rechte für eine bestimmte Aktion in einem Modul hat.
	 *
	 * @param module Modul, in dem die Aktion ausgeführt werden soll
	 * @param operation Aktion, die in dem Modul ausgeführt werden soll
	 */
	checkPermissions(module: Module, operation: Operation): boolean {
		let authorized = false;

		const token = localStorage.getItem('jwt');
		const permissions = this.jwtHelper.decodeToken(token)['permissions'].split('-');
		authorized = (permissions[operation][63 - module] === '1' ? true : false);

		return authorized;
	}

	/**
	 * API Request zum Einloggen
	 * @param username Nutzername
	 * @param password Passwort
	 * @param rememberMe Anmeldedaten merken
	 */
	login(username: string, password: string, rememberMe: boolean, twoFactorCode: string): Promise<User> {
		this.logService.log("Information", 1, "Login Attempt", "A client is trying to log in")
		const body = { Username: username, Password: password, RememberMe: rememberMe, TwoFactorCode: twoFactorCode };
		return new Promise<User>(async resolve => {
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/login`, body, { withCredentials: true }).toPromise());
		});
	}

	/**
	 * API Request zum Ausloggen
	 */
	logout(): Promise<Message> {
		return new Promise<Message>(async resolve => {
			this.logService.log("Information", 1, "Logout Attempt", "A client is trying to log out")
			const options = await this.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/auth/logout`, options).toPromise());
		});
	}

	/**
	 * API Request zum Erneuern der Tokens
	 */
	async refresh() {
		const body = { AccessToken: localStorage.getItem('jwt'), RefreshToken: localStorage.getItem('refreshToken') };
		return this.http.post<any>(`${environment.apiUrl}/auth/refresh`, body, { withCredentials: true })
			.toPromise()
			.then(res => res as Credentials)
	}

	/**
	 * API Request zum prüfen von Zwei-Faktor Authentizierung
	 * @param username Nutzername
	 */
	requiresTwoFactor(username: string): Promise<User> {
		this.logService.log("Information", 1, "2FA Check", "A client is checking if it needs 2FA to log in")
		const body = { Username: username };
		return new Promise<User>(async resolve => {
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/TwoFactorRequired`, body, { withCredentials: true }).toPromise());
		});
	}

	/**
	 * Überprüft ob der Nutzer die Policy erfüllt
	 */
	isAuthorized(requiredPolicy: Policy): boolean {
		const token = localStorage.getItem('jwt');
		if (token) {
			const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
			if (role) {
				if (requiredPolicy == Policy.AllUsers) {
					if (
						role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator' || role === 'CustomerUser_Level_3' ||
						role === 'CustomerUser_Level_2' || role === 'CustomerUser_Level_1' || role === 'CustomerUser'
					) {
						return true;
					}
				}
					// 2025-03-06 mmathofer: Da die vereinzelten SecurityLevels unterhalb von CustomerUser niemals sinnvoll eingesetzt wurden, im Map-Bereich aber eine
					// Einschränkung darstellen da wir immer nur CustomerUser anlegen, niemals hingegen CustomerUser_Level_x, werden diese nun alle 
					// mit Policy.AllUsers gleichgesetzt
				else if (requiredPolicy == Policy.UserSecurityLevel_1) {
					//if (
					//	role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator' || role === 'CustomerUser_Level_3' ||
					//	role === 'CustomerUser_Level_2' || role === 'CustomerUser_Level_1'
					//) {
					//	return true;
					//}
					if (
						role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator' || role === 'CustomerUser_Level_3' ||
						role === 'CustomerUser_Level_2' || role === 'CustomerUser_Level_1' || role === 'CustomerUser'
					) {
						return true;
					}
				} else if (requiredPolicy == Policy.UserSecurityLevel_2) {
					//if (
					//	role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator' ||
					//	role === 'CustomerUser_Level_3' || role === 'CustomerUser_Level_2'
					//) {
					//	return true;
					//}
					if (
						role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator' || role === 'CustomerUser_Level_3' ||
						role === 'CustomerUser_Level_2' || role === 'CustomerUser_Level_1' || role === 'CustomerUser'
					) {
						return true;
					}
				} else if (requiredPolicy == Policy.UserSecurityLevel_3) {
					//if (
					//	role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator' || role === 'CustomerUser_Level_3'
					//) {
					//	return true;
					//}
					if (
						role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator' || role === 'CustomerUser_Level_3' ||
						role === 'CustomerUser_Level_2' || role === 'CustomerUser_Level_1' || role === 'CustomerUser'
					) {
						return true;
					}
				} else if (requiredPolicy == Policy.CustomerAdmins) {
					if (
						role === 'ViSyAdministrator' || role === 'VisyUser' || role === 'CustomerAdministrator'
					) {
						return true;
					}
				} else if (requiredPolicy == Policy.ViSyUser) {
					if (
						role === 'ViSyAdministrator' || role === 'ViSyUser'
					) {
						return true;
					}
				} else if (requiredPolicy == Policy.ViSyAdmins) {
					if (
						role === 'ViSyAdministrator'
					) {
						return true;
					}
				}
			}
		}
		return false;
	}

	logboxPubkeyApiGetFingerprintServerPubkey(): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/auth/LogboxPubkeyApiGetFingerprintOfServerPubkey`, options).toPromise());
		});
	}

	getTwoFactorCode(): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/auth/GenerateTwoFactorQRCode`, options).toPromise());
		});
	}
	
	validateTwoFactorCode(p_code: string): Promise<any> {
		return new Promise<any>(async resolve => {
			const body = { code: p_code };
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/TwoFactorValidate`, body, options).toPromise());
		});
	}

	check2faEnabled(p_userId: string): Promise<any> {
		const body = { userId: p_userId };
		return new Promise<User>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/IsTwoFactorEnabled`, body, options).toPromise());
		});
	}

	check2faOnLoginEnabled(p_userId: string): Promise<any> {
		const body = { userId: p_userId };
		return new Promise<User>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/IsTwoFactorOnLoginEnabled`, body, options).toPromise());
		});
	}

	check2faCode(code2fa: string): Promise<any> {
		const body = { code: code2fa };
		return new Promise<User>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/TwoFactorValidate`, body, options).toPromise());
		});
	}

	setTwoFactor(p_enabled: boolean, p_onEveryLogin: boolean, p_userId: string = 'current'): Promise<any> {
		const body = { userId: p_userId, enabled: p_enabled, onEveryLogin: p_onEveryLogin };
		return new Promise<User>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/SetTwoFactorLoginFlag`, body, options).toPromise());
		});
	}

	enableTwoFactorLogin(p_userId: string = 'current'): Promise<any> {
		const body = { userId: p_userId, enabled: true, onEveryLogin: true };
		return new Promise<User>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/SetTwoFactorLoginFlag`, body, options).toPromise());
		});
	}

	disableTwoFactorLogin(p_userId: string = 'current', ): Promise<any> {
		const body = { userId: p_userId, enabled: false, onEveryLogin: false };
		return new Promise<User>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/auth/SetTwoFactorLoginFlag`, body, options).toPromise());
		});
	}

	getUserIdAndName(): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/auth/CurrentUserIdAndName`, options).toPromise());
		});
	}

	getUserId(): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/auth/CurrentUserId`, options).toPromise());
		});
	}

	getUserName(): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/auth/CurrentUserName`, options).toPromise());
		});
	}
}
